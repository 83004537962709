import request from '@/utils/request_trans'
import qs from 'qs'
/**
 * 登录
 */
export function applet_login(data) {
    return request({
        url: 'api/home/applet_login/',
        method: 'post',
        data,
        headers: {
           'Content-Type': 'application/json'
         },
    })
}
/**
 * 注册
 */
export function applet_enroll(data) {
    return request({
        url: 'api/home/applet_acc_manager/',
        method: 'post',
        data
    })
}

// 获取翻译语种
export function getLanguages(query) {
    return request({
        url: 'api/translate/all_lang/',
        method: 'get',
        params: query
    })
}

// 检索任务状态  
export function getData(data) {
    return request({
        url: 'api/translate/search/',
        method: 'post',
        // data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data:qs.stringify(data)
    })
}

//   删除文档翻译记录
export function deleteRecord(data) {
    return request({
        url: 'api/translate/delete/',
        method: 'post',
        // data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data:qs.stringify(data)
    })
}
//  下载
export function download(data) {
    return request({
        url: 'api/translate/download/',
        method: 'post',
       // data,
       headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    data:qs.stringify(data)
    })
}