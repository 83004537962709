<template>
  <div class="container">
    <div class="box">
      <div class="box_left">
        <div class="bottom">北京盈科千信科技有限公司</div>
        <div>地址：北京市海淀区中关村南大街48号</div>
      </div>
      <div class="splitLine"></div>
      <div class="box_right">
        <div class="email">邮箱：product@increscence.net</div>
        <div class="record">
          <div>Copyright © 2022</div>
          <img src="../assets/imgs/badge.png" alt="">
          <div>京ICP备15054044号-1</div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style   lang="less" scoped>
  .container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 65px;
    min-width: 1380px;
    background-color: #B14A75;
   
  }
  .box{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

  }
  .splitLine{
    width: 1px;
    height: 56px;
    background-color: #fff;
    margin: 0px 20px;
  }
  .box_right img{
    width: 20px;
    margin: 0 5px;
  }
  .record{
    display: flex;
    align-items: center;
  }
  .bottom {
    margin-bottom: 20px;
    text-align: end;
  }
  .email{
    margin-bottom: 14px;
  }
</style>