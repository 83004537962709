<template>
  <div class="logins">
    <!-- 登录页面 -->
    <!-- <div class="leftImg">
    </div> -->
    <div class="loginTitle">
      <img src="../../assets/imgs/logo.png" alt="" />
      <h2>青梨AI科研服务平台</h2>
    </div>
    <!-- <div @click="enroll()">aaa</div> -->
    <!-- 账号密码登录 -->
    <div class="boxs">
      <div class="txt_s">
        <div class="txt_img_">
          <img
            style="width: 270px"
            src="../../assets/imgs/logo/txt_00.png"
            alt=""
          />
        </div>

        <br />
        <img
          style="width: 450px"
          src="../../assets/imgs/logo/txt_01.png"
          alt=""
        />
      </div>
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        class="login-form"
        v-show="loginType"
      >
        <div class="formTop">
          <div class="login-title">账号密码登录</div>
          <img
            @click="changeLoginType"
            src="../../assets/imgs/QRcode.png"
            alt=""
          />
        </div>
        <el-form-item label="" prop="username">
          <el-input v-model="form.username" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item label="" prop="password">
          <el-input v-model="form.password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="checked">下次自动登录</el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button class="log" type="primary" @click="submitForm('form')"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
      <!-- 扫码登录 -->
      <div class="scanCode" style="border-radius: 20px" v-show="!loginType">
        <div class="formTop2">
          <div class="login-title">手机扫码登录</div>

          <!-- <img
            @click="changeLoginType"
            src="../../assets/imgs/scanCode.png"
            alt=""
          /> -->
        </div>
        <div class="qrcodeImgBox">
          <div class="mark">
            <img
              v-if="!refreshQrcode && !network"
              class="qrcodeImg"
              :src="qrcodeImg"
              alt=""
            />
          </div>
          <div class="refreshQrcode" v-if="refreshQrcode" @click="getQrcode">
            <img
              style="width: 40px; margin-bottom: 5px"
              src="../../assets/imgs/refreshQrcode.png"
              alt=""
            />
            <div style="color: white" v-if="!network">
              二维码已过期，点击刷新
            </div>
          </div>
          <div class="refreshQrcode" v-if="network" @click="getQrcode">
            <img
              style="width: 40px; margin-bottom: 5px"
              src="../../assets/imgs/查重与校对/网络故障，网络问题，互联网.png"
              alt=""
            />
            <div style="color: white">
              网络故障，请检查网络后<span class="tap">刷新</span>
            </div>
            <!-- <div style="color:rgba(0, 0, 0, 0.6);">请检查网络</div> -->
          </div>
          <!-- <div class="el-loading-spinner" v-loading="refreshLoding" v-if="refreshLoding == true" element-loading-text="重新刷新" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)" style="width: 100%"></div> -->
          <div class="loginText">
            <img
              style="width: 24px"
              src="../../assets/imgs/logo/weixin.png"
              alt=""
            />
            请使用微信扫码登录
          </div>
          <!-- <el-checkbox v-model="checked">下次自动登录</el-checkbox> -->
        </div>
      </div>
    </div>
    <van-dialog
      v-model="show"
      show-cancel-button
      confirm-button-text="进入青梨小程序"
      confirm-button-color="#8268df"
      @cancel="closepopup"
      @confirm="toapplet"
    >
      <div class="popup">
        <div class="popup_top">
          <img @click="closepopup" src="../../assets/imgs/close.png" alt="" />
        </div>
        <p style="margin-top: 10vw">
          为优化您的使用体验，建议您前往青梨小程序进行查看，点击”进入青梨小程序“按钮即可进入。
        </p>
      </div>
    </van-dialog>
    <!-- <ComFooter></ComFooter> -->
    <div class="container">
      <div class="box">
        <div
          class="box_left box_right"
          style="display: flex; align-items: center"
        >
          <div
            class="bottom_txt_css"
            style="padding: 0 10px; border-right: 1px solid #aaa"
          >
            北京盈科千信科技有限公司
          </div>
          <!-- class="bottom" -->
          <div
            class="bottom_txt_css"
            style="padding: 0 10px; border-right: 1px solid #aaa"
          >
            地址：北京市海淀区中关村南大街48号
          </div>
          <div
            class="bottom_txt_css"
            style="padding: 0 10px; border-right: 1px solid #aaa"
          >
            邮箱：product@increscence.net
          </div>
          <!-- class="email" -->
          <div class="record ottom_txt_css" style="padding: 0 10px">
            <div>Copyright © 2022</div>
            <img src="../../assets/imgs/badge.png" alt="" />
            <div>京ICP备15054044号-1</div>
          </div>
        </div>
        <!-- <div class="splitLine"></div>
        <div class="box_right">
          
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { qrcodeLogin } from "@/Api/login";
import ComFooter from "@/components/ComFooter.vue";

import request from "@/utils/request";
import { personalMessage, information, fetchMessage } from "@/Api/userMessage";
import { applet_login, applet_enroll } from "@/Api/translation";
import { parseTime } from "../../utils/date";
import { organinfo, journal_list } from "@/Api/journals";
import { scholarcyUser, getScholarcy } from "@/Api/scholarcy_writeful";
import { writefulUser, getWriteful } from "@/Api/scholarcy_writeful01";
import {
  getUser,
  judge_user,
  confirmUser,
  organ_association,
} from "@/Api/unquote";
import store from "@/store";

export default {
  inject: ["reloads"],
  data() {
    return {
      isshow: localStorage.getItem("isshow"),
      link: "",
      show: false,
      timer: "", // 定时任务
      // refreshLoding:true, // 刷新小程序二维码
      // loading: true,
      refreshQrcode: false, // 二维码已过期，点击刷新
      oper: "get", // get 获取小程序码    check 检测小程序码是否过期
      uid: "",
      qrcodeImg: "", // 微信小程序二维码图片
      loginType: false, // 登录方式 true：账号密码 false：微信扫码
      checked: false,
      form: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
          { min: 3, max: 10, message: "用户名3-5位", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          { min: 3, max: 10, message: "密码3-5位", trigger: "blur" },
        ],
      },
      network: false,
      test: false,
    };
  },
  created() {
    if (
      window.sessionStorage.getItem("enroll") &&
      window.sessionStorage.getItem("enroll_id")
    ) {
      this.$router.push({
        name: "Enroll",
        query: {
          chatroom: window.sessionStorage.getItem("enroll_id"),
        },
      });
      return;
    }

    // window.setInterval(() => {
    // setTimeout(this.getQrcode(), 0);
    // }, 3000);
    this.getQrcode();
  },
  mounted() {
    // 通过userAgent判断是否是手机端
    var u = navigator.userAgent;
    var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    console.log(isAndroid, isiOS);
    if (isAndroid || isiOS) {
      this.getapplink();
    }
    //检查小程序二维码的状态
  },
  beforeDestroy() {
    if (this.getQrcode) {
      //如果定时器还在运行 或者直接关闭，不用判断
      clearInterval(this.getQrcode); //关闭
    }
  },
  components: {
    ComFooter,
  },

  methods: {
    // enroll() {
    //   this.$router.push({
    //     name: "Enroll",
    //     query: {
    //       chatroom: "AddTest@chatroom",
    //     },
    //   });
    // },
    // 改变登录方式
    changeLoginType() {
      var loginType = this.loginType;
      this.loginType = !loginType;
      if (loginType) {
        // console.log("aaa")
        // this.oper = "get";
        // this.uid = "";
        this.getQrcode(); //获取微信的登录二维码
      }
    },

    submitForm(formName) {
      window.localStorage.setItem(
        "baseURL",
        "https://xcx.newacademic.net/api/"
      );
      this.$refs[formName].validate((valid) => {
        // console.log(valid) 验证通过为true，有一个不通过就是false
        if (valid) {
          this.$message({
            message: "暂未开通账号登录功能！",
            type: "error",
          });
          // this.$router.push({
          //   name: "Search",
          // });
        } else {
          console.log("登录失败");
          return false;
        }
      });
    },
    // 获取小程序二维码
    getQrcode() {
      if (this.refreshQrcode == true) {
        this.refreshQrcode = false;
      }
      request
        .get("user/web_login/", {
          params: {
            oper: this.oper,
            uid: this.uid,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.error_code == 0) {
            if (res.data.data.status == 0) {
              // 二维码仍有效
              if (!localStorage.getItem("token")) {
                setTimeout(() => {
                  this.getQrcode();
                }, 3000);
              }
            } else if (res.data.data.status == 1) {
              // 二维码已过期
              this.oper = "get";
              this.uid = "";
              this.refreshQrcode = true;
            } else if (res.data.data.status == 2) {
              // 登录成功
              localStorage.setItem("token", res.data.data.token.token);
              localStorage.setItem("email", res.data.data.token.email);
              localStorage.setItem("faculty", res.data.data.token.faculty);
              localStorage.setItem("id", res.data.data.token.id);
              localStorage.setItem("name", res.data.data.token.name);
              localStorage.setItem("phone", res.data.data.token.phone);
              localStorage.setItem("status", res.data.data.token.status);
              this.$message({
                message: "登录成功！",
                type: "success",
              });
              window.localStorage.setItem(
                "baseURL",
                "https://xcx.newacademic.net/api/"
              );
              if (res.data.data.is_first == true) {
                // this.$router.push({
                //   name: "Search",
                //   params: { one: "yes" },
                // });
                this.$router.push({ path: "/home" });
              } else {
                personalMessage()
                  .then((res) => {
                    // console.log(res);
                    // this.name = res.data.data.name;
                    // this.email = res.data.data.email;
                    // this.phone = res.data.data.phone;
                    sessionStorage.setItem(
                      "mes",
                      JSON.stringify(res.data.data)
                    );
                    localStorage.setItem("code", res.data.data.code);
                    localStorage.setItem("org_ode", res.data.data.org_code);
                    localStorage.setItem("org_name", res.data.data.org_name);
                    localStorage.setItem(
                      "organ_product",
                      JSON.stringify(res.data.data.organ_product)
                    );
                    sessionStorage.setItem("headshot", res.data.data.avatarUrl);
                    sessionStorage.setItem("unionid", res.data.data.unionid);
                    sessionStorage.setItem(
                      "id_photo_state",
                      res.data.data.id_photo_state
                    );
                    let code_initial = res.data.data.code;
                    if (window.sessionStorage.getItem("routing") == "/read") {
                      this.$router.push({
                        path: window.sessionStorage.getItem("routing"),
                        query: JSON.parse(
                          window.sessionStorage.getItem("routingQuery")
                        ),
                      });
                      return;
                    }
                    var tmp = Date.parse(new Date()).toString();
                    tmp = tmp.substr(0, 10);

                    let event = code_initial + "|" + tmp;
                    // console.log(event);
                    let codesid = {
                      code: event,
                    };
                    applet_login(codesid)
                      .then((res) => {
                        // 提交成功 弹出提示框并刷新页面
                        // console.log(res);
                        if (res.data.status == 1) {
                          if (res.data.data.login == "not_exists") {
                            this.register();
                          } else {
                            window.sessionStorage.removeItem("routing");
                            window.sessionStorage.removeItem("linkPage");
                            window.sessionStorage.removeItem("linkOrigins");
                          }
                        } else {
                          localStorage.setItem(
                            "token_USID",
                            res.data.data.token.token
                          );
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                        window.sessionStorage.removeItem("routing");
                        window.sessionStorage.removeItem("linkPage");
                        window.sessionStorage.removeItem("linkOrigins");
                      });
                    let open_code = {
                      open_id: code_initial,
                      organ_name: localStorage.getItem("org_ode"),
                    };
                    organinfo(open_code)
                      .then((res) => {
                        // console.log('a',res);
                        localStorage.setItem("organ_info", res.data.data);
                        // var that = this;
                        // setTimeout(function () {
                        this.organ_Info();
                        // }, 500);
                      })
                      .catch((err) => {
                        console.log(err);
                        window.sessionStorage.removeItem("routing");
                        window.sessionStorage.removeItem("linkPage");
                        window.sessionStorage.removeItem("linkOrigins");
                      });
                    if (
                      window.sessionStorage.getItem("routing") == "/citation" ||
                      window.sessionStorage.getItem("routing") ==
                        "/citationEn" ||
                      window.sessionStorage.getItem("routing") ==
                        "/citationList" ||
                      window.sessionStorage.getItem("routing") ==
                        "/citationPersonal"
                    ) {
                      let user_citation = {
                        user_openid: code_initial,
                        institution_name: localStorage.getItem("org_name"),
                      };
                      // window.sessionStorage.removeItem("webNav");
                      getUser(user_citation)
                        .then((res) => {
                          // console.log(res);
                          if (res.data.have_user !== 1) {
                            let chinese_name = {
                              keyword: localStorage.getItem("name"),
                            };
                            organ_association(chinese_name)
                              .then((res) => {
                                // console.log(res);
                                // localStorage.setItem("organ_info", res.data.data);
                                // return
                                let register_data = {
                                  ch_user_name: localStorage.getItem("name"),
                                  en_user_name: res.data.data,
                                  institution_name:
                                    localStorage.getItem("org_name"),
                                  user_openid: code_initial,
                                  institution_code:
                                    localStorage.getItem("org_ode"),
                                };
                                confirmUser(register_data)
                                  .then((res) => {
                                    // console.log(res);
                                    let openCode = {
                                      user_openid: code_initial,
                                    };
                                    judge_user(openCode)
                                      .then((res) => {
                                        // console.log(res);
                                        // this.en_name = res.data.user_mes[0].en_user_name;

                                        localStorage.setItem(
                                          "en_name",
                                          res.data.user_mes[0].en_user_name
                                        );
                                      })
                                      .catch((err) => {
                                        console.log(err);
                                        window.sessionStorage.removeItem(
                                          "routing"
                                        );
                                        window.sessionStorage.removeItem(
                                          "linkPage"
                                        );
                                        window.sessionStorage.removeItem(
                                          "linkOrigins"
                                        );
                                      });
                                    // localStorage.setItem("organ_info", res.data.data);
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                    window.sessionStorage.removeItem("routing");
                                    window.sessionStorage.removeItem(
                                      "linkPage"
                                    );
                                    window.sessionStorage.removeItem(
                                      "linkOrigins"
                                    );
                                  });
                              })
                              .catch((err) => {
                                console.log(err);
                                window.sessionStorage.removeItem("routing");
                                window.sessionStorage.removeItem("linkPage");
                                window.sessionStorage.removeItem("linkOrigins");
                              });
                          } else {
                            let openCode = {
                              user_openid: code_initial,
                            };
                            judge_user(openCode)
                              .then((res) => {
                                // console.log(res);
                                // this.en_name = res.data.user_mes[0].en_user_name;

                                localStorage.setItem(
                                  "en_name",
                                  res.data.user_mes[0].en_user_name
                                );
                              })
                              .catch((err) => {
                                console.log(err);
                                window.sessionStorage.removeItem("routing");
                                window.sessionStorage.removeItem("linkPage");
                                window.sessionStorage.removeItem("linkOrigins");
                              });
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                          window.sessionStorage.removeItem("routing");
                          window.sessionStorage.removeItem("linkPage");
                          window.sessionStorage.removeItem("linkOrigins");
                        });
                    } else if (
                      window.sessionStorage.getItem("routing") ==
                        "/scholarcyUpload" ||
                      window.sessionStorage.getItem("routing") ==
                        "/scholarcyList"
                    ) {
                      let scholary_code = {
                        org_name: localStorage.getItem("org_name"),
                        open_id: code_initial,
                      };
                      getScholarcy(scholary_code)
                        .then((res) => {
                          // console.log("a", res);
                          if (!res.data.token.token) {
                            let scholary_code_edit = {
                              org_name: localStorage.getItem("org_name"),
                              open_id: code_initial,
                              name: localStorage.getItem("name"),
                              org_code: localStorage.getItem("org_ode"),
                            };
                            scholarcyUser(scholary_code_edit)
                              .then((res) => {
                                // console.log(res);
                              })
                              .catch((err) => {
                                console.log(err);
                                window.sessionStorage.removeItem("routing");
                                window.sessionStorage.removeItem("linkPage");
                                window.sessionStorage.removeItem("linkOrigins");
                              });
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                          window.sessionStorage.removeItem("routing");
                          window.sessionStorage.removeItem("linkPage");
                          window.sessionStorage.removeItem("linkOrigins");
                        });
                    } else if (
                      window.sessionStorage.getItem("routing") ==
                        "/writefulUpload" ||
                      window.sessionStorage.getItem("routing") ==
                        "/writefulList"
                    ) {
                      let scholary_code01 = {
                        org_name: localStorage.getItem("org_name"),
                        open_id: code_initial,
                      };
                      getWriteful(scholary_code01)
                        .then((res) => {
                          // console.log("a", res);
                          if (!res.data.token.token) {
                            let scholary_code_edit01 = {
                              org_name: localStorage.getItem("org_name"),
                              open_id: code_initial,
                              name: localStorage.getItem("name"),
                              org_code: localStorage.getItem("org_ode"),
                            };
                            writefulUser(scholary_code_edit01)
                              .then((res) => {
                                // console.log(res);
                              })
                              .catch((err) => {
                                console.log(err);
                                window.sessionStorage.removeItem("routing");
                                window.sessionStorage.removeItem("linkPage");
                                window.sessionStorage.removeItem("linkOrigins");
                              });
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                          window.sessionStorage.removeItem("routing");
                          window.sessionStorage.removeItem("linkPage");
                          window.sessionStorage.removeItem("linkOrigins");
                        });
                    } else if (
                      window.sessionStorage.getItem("routing") ==
                        "/journalsDetail" ||
                      window.sessionStorage.getItem("routing") == "/essay"
                    ) {
                      let open_code = {
                        open_id: code_initial,
                        organ_name: localStorage.getItem("org_ode"),
                      };
                      organinfo(open_code)
                        .then((res) => {
                          // console.log('a',res);
                          localStorage.setItem("organ_info", res.data.data);
                          // var that = this;
                          // setTimeout(function () {
                          this.organ_Info01();
                          // }, 500);
                        })
                        .catch((err) => {
                          console.log(err);
                          window.sessionStorage.removeItem("routing");
                          window.sessionStorage.removeItem("linkPage");
                          window.sessionStorage.removeItem("linkOrigins");
                          window.sessionStorage.removeItem("journals_detail");
                        });
                    }
                    if (
                      window.sessionStorage.getItem("routing") !==
                        "/academic" &&
                      window.sessionStorage.getItem("routing") !==
                        "/journalsDetail" &&
                      window.sessionStorage.getItem("routing") !== "/essay"
                    ) {
                      var that = this;
                      setTimeout(function () {
                        // console.log("b");
                        if (
                          window.sessionStorage.getItem("routing") &&
                          window.sessionStorage.getItem("linkPage") &&
                          window.sessionStorage.getItem("linkOrigins")
                        ) {
                          that.$router.push({
                            path: window.sessionStorage.getItem("routing"),
                            query: {
                              webPage:
                                window.sessionStorage.getItem("linkPage"),
                              origins:
                                window.sessionStorage.getItem("linkOrigins"),
                            },
                          });
                        } else if (
                          window.sessionStorage.getItem("routing") &&
                          window.sessionStorage.getItem("linkPage") &&
                          !window.sessionStorage.getItem("linkOrigins")
                        ) {
                          that.$router.push({
                            path: window.sessionStorage.getItem("routing"),
                            query: {
                              webPage:
                                window.sessionStorage.getItem("linkPage"),
                            },
                          });
                        } else if (
                          window.sessionStorage.getItem("routing") &&
                          !window.sessionStorage.getItem("linkPage") &&
                          !window.sessionStorage.getItem("linkOrigins")
                        ) {
                          that.$router.push({
                            path: window.sessionStorage.getItem("routing"),
                          });
                        } else {
                          // that.$router.push({
                          //   name: "Search",
                          //   query: { webPage: 1 },
                          // });
                          that.$router.push({ path: "/home" });
                        }

                        window.sessionStorage.removeItem("routing");
                        window.sessionStorage.removeItem("linkPage");
                        window.sessionStorage.removeItem("linkOrigins");
                      }, 1000);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    window.sessionStorage.removeItem("routing");
                    window.sessionStorage.removeItem("linkPage");
                    window.sessionStorage.removeItem("linkOrigins");
                  });
              }
            } else {
              // 获取二维码
              this.qrcodeImg = res.data.data.base64;
              this.uid = res.data.data.uid;
              this.oper = "check";
              setTimeout(() => {
                this.getQrcode();
              }, 3000);
            }
          }
          // if (res.data.code == 1) {
          //   this.$message({
          //     message: res.data.remarks,
          //     type: 'warning'
          //   });
          // }
        })
        .catch((err) => {
          console.log(err);
          this.network = true;
        });
    },
    toapplet() {
      window.open(this.link);
    },
    closepopup() {
      this.show = false;
      localStorage.setItem("isshow", "0");
    },
    getapplink() {
      if (window.sessionStorage.getItem("routing") == "/read") {
        var query =
          "task_id=" +
          JSON.parse(window.sessionStorage.getItem("routingQuery")).task_id;
        request
          .get("user/get_applet_link/", {
            params: {
              app: "qingli",
              path: "packageB/pages/Preview/Preview",
              query: query,
            },
            headers: {
              token: "",
            },
          })
          .then((res) => {
            this.link = res.data.data.link;
            this.show = true;
            window.localStorage.setItem("isshow", "0");
          })
          .catch((err) => {});
      } else {
        if (sessionStorage.getItem("h5")) {
          window.location.href = "https://qinglih5.newacademic.net/";
          window.localStorage.setItem("isshow", "0");
          sessionStorage.removeItem("h5");
          return;
        }
        request
          .get("user/get_applet_link/", {
            params: {
              // app: "qingli",
              path: "pages/manage/manage",
              // query: query,
            },
            headers: {
              token: "",
            },
          })
          .then((res) => {
            this.link = res.data.data.link;
            this.show = true;
            window.localStorage.setItem("isshow", "0");
          })
          .catch((err) => {});
        // console.log(this.$route.query.webPage && this.$route.query.webPage == 2)
        return;
        // this.link = "https://qinglih5.newacademic.net/";
        //
        // window.open(this.link);
        window.location.href = "https://qinglih5.newacademic.net/";
        // const link = document.createElement("a");
        // link.href = "https://qinglih5.newacademic.net/";
        // link.target = "_blank";
        // document.body.appendChild(link); // 添加到文档
        // link.click(); // 触发点击
        // document.body.removeChild(link); // 删除链接
        // this.show = true;
        window.localStorage.setItem("isshow", "0");
      }
    },
    applet_login_change() {
      if (localStorage.getItem("code")) {
        var tmp = Date.parse(new Date()).toString();
        tmp = tmp.substr(0, 10);
        let code_ = localStorage.getItem("code");
        let event = code_ + "|" + tmp;
        let codesid = {
          code: event,
        };
        applet_login(codesid)
          .then((res) => {
            // 提交成功 弹出提示框并刷新页面
            // console.log(res);
            if (res.data.status !== 1) {
              localStorage.setItem("token_USID", res.data.data.token.token);
            }
          })
          .catch((err) => {
            console.log(err);
            window.sessionStorage.removeItem("routing");
            window.sessionStorage.removeItem("linkPage");
            window.sessionStorage.removeItem("linkOrigins");
          });
      }
    },
    register() {
      var g = new Date().getTime();
      // console.log(g);
      let date01 = parseTime(g, "{y}-{m}-{d} {h}:{i}:{s}");
      let date02 = "2099-12-31 00:00:00";
      // console.log(date01, date02);
      let enroll = {
        org_code: localStorage.getItem("org_ode"),
        user_code: localStorage.getItem("code"),
        user_type: 0,
        surplus: 10,
        create_time: date01,
        overdue_time: date02,
      };
      applet_enroll(enroll)
        .then((res) => {
          // console.log(res);
          if (res.data.status == 0) {
            if (res.data.msg == "此用户已开通" || res.data.msg == "开通成功") {
              // that.isExist();
              this.applet_login_change();
            } else {
              window.sessionStorage.removeItem("routing");
              window.sessionStorage.removeItem("linkPage");
              window.sessionStorage.removeItem("linkOrigins");
            }
          } else {
            if (res.data.data == "repeat") {
            } else {
              window.sessionStorage.removeItem("routing");
              window.sessionStorage.removeItem("linkPage");
              window.sessionStorage.removeItem("linkOrigins");
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    organ_Info() {
      let getList = {
        language: 2,
        field: "name",
        val: "",
      };

      journal_list(getList)
        .then((res) => {
          console.log(Object.keys(res.data.data).length);
          if (Object.keys(res.data.data).length == 0) {
            localStorage.setItem("subscribeOr", "0");
            // window.sessionStorage.removeItem("linkPage");
            if (
              window.sessionStorage.getItem("linkPage") !== "4" &&
              window.sessionStorage.getItem("linkPage") !== 4
            ) {
              this.$router.push({
                path: window.sessionStorage.getItem("routing"),
                query: {
                  webPage: window.sessionStorage.getItem("linkPage"),
                },
              });
            } else {
              this.$router.push({
                path: window.sessionStorage.getItem("routing"),
              });
            }
          } else {
            localStorage.setItem("subscribeOr", "1");
            this.$router.push({
              path: window.sessionStorage.getItem("routing"),
              query: {
                webPage: window.sessionStorage.getItem("linkPage"),
              },
            });
          }
          // console.log("a");
          // console.log("a")
        })
        .catch((err) => {
          console.log(err);
          window.sessionStorage.removeItem("routing");
          window.sessionStorage.removeItem("linkPage");
          window.sessionStorage.removeItem("linkOrigins");
        });
    },
    organ_Info01() {
      let getList = {
        language: 2,
        field: "name",
        val: "",
      };
      console.log(window.sessionStorage.getItem("journals_detail"));
      console.log(JSON.parse(window.sessionStorage.getItem("journals_detail")));
      journal_list(getList)
        .then((res) => {
          console.log(Object.keys(res.data.data).length);
          if (Object.keys(res.data.data).length == 0) {
            localStorage.setItem("subscribeOr", "0");
            // window.sessionStorage.removeItem("linkPage");
            if (
              window.sessionStorage.getItem("linkPage") !== "4" &&
              window.sessionStorage.getItem("linkPage") !== 4
            ) {
              this.$router.push({
                path: window.sessionStorage.getItem("routing"),
                query: JSON.parse(
                  window.sessionStorage.getItem("journals_detail")
                ),
              });
            } else {
              this.$router.push({
                path: window.sessionStorage.getItem("routing"),
              });
            }
          } else {
            localStorage.setItem("subscribeOr", "1");
            this.$router.push({
              path: window.sessionStorage.getItem("routing"),
              query: JSON.parse(
                window.sessionStorage.getItem("journals_detail")
              ),
            });
          }
          // console.log("a");
          // console.log("a")
        })
        .catch((err) => {
          console.log(err);
          window.sessionStorage.removeItem("routing");
          window.sessionStorage.removeItem("linkPage");
          window.sessionStorage.removeItem("linkOrigins");
          window.sessionStorage.removeItem("journals_detail");
        });
    },
  },
};
</script>

<style  lang="less" scoped>
.logins {
  background: url(../../assets/imgs/loginBanner_new.png) no-repeat left 74%;
  // background-repeat: repeat;
  background-size: cover;
  // background-size: 100% 100%;
}
@media only screen and (min-width: 1200px) {
  .logins {
    height: 100vh;
  }
  .loginTitle {
    height: 8%;
    // height: 85px;
    display: flex;
    align-items: center;
    // position: fixed;
    // padding-top: 3%;
    padding-left: 5%;
    img {
      width: 40px;
      border-radius: 50%;
      margin-right: 15px;
    }
    h2 {
      color: #333;
    }
  }
  .formTop {
    display: flex;
    margin-bottom: 25px;
    align-items: center;

    img {
      width: 60px;
      margin-right: -20px;
      margin-top: -10px;
    }
  }
  .leftImg {
    background-color: #fff;
    width: 500px;
    height: 380px;
    margin-right: 50px;
  }
  .boxs {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
    // height: calc(100vh - 170px);
    // max-height: 1200px;
    margin-right: 5%;
    height: 84%;
  }
  .txt_img_ {
    background: #b14a75;
    padding: 20px;
    margin: 20px 0;
    display: inline-block;
    border-radius: 50px;
  }
  .login-form {
    width: 320px;
    height: 370px;
    background-color: #ffffff;
    padding: 20px 30px;
    margin-left: 46%;
  }

  /* 标题 */
  .login-title {
    color: #303133;
    // text-align: end;
    font-size: 18px;
    font-weight: 700;
    // width: 80%;
    // margin-right: 44px;
  }
  .log {
    // margin-left: 80px;
    width: 100%;
  }

  .scanCode {
    // width: 320px;
    // height: 370px;
    width: 344px;
    // height: 400px;
    background-color: #ffffff;
    padding: 24px 32px;
    text-align: center;
    // margin-left: 46%;
  }

  .formTop2 {
    display: flex;
    margin-bottom: 12px;
    align-items: center;
    justify-content: center;

    img {
      width: 60px;
      margin-right: 6px;
      margin-top: -8px;
    }
  }
  .qrcodeImgBox {
    text-align: center;
    height: 70%;
    .loginText {
      color: #333;
      margin-top: 20px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .mark {
    // height: 204px;
    height: 280px;
  }
  .qrcodeImg {
    width: 280px;
  }

  .refreshQrcode {
    margin-top: -245px;
    height: 210px;
    width: 210px;
    margin-left: 33px;
    position: absolute;
    padding: 75px 0;
    background: rgba(0, 0, 0, 0.6);
    cursor: pointer;
  }
  .internet {
    margin-top: -300px;
    height: 210px;
    width: 210px;
    margin-left: 55px;
    position: absolute;
    padding: 75px 0;
    // background: rgba(0, 0, 0, 0.6);
    cursor: pointer;
  }
  .container {
    height: 8%;
    // height: 85px;
    // background-color: #f1f2f6;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #aaa;
    font-size: 10px;
  }
  .splitLine {
    width: 1px;
    height: 56px;
    background-color: #aaa;
    margin: 0px 20px;
  }
  .box_right img {
    width: 20px;
    margin: 0 5px;
  }
  .record {
    display: flex;
    align-items: center;
  }
  .bottom {
    margin-bottom: 20px;
    margin-left: 61px;
  }
  .email {
    margin-bottom: 14px;
  }
  .tap {
    color: rgb(103, 160, 247);
  }
  .tap:hover {
    color: rgb(0, 183, 255);
  }
}
@media only screen and (max-width: 1200px) {
  // body {
  //     background-color: lightblue;
  //     width: 100vw !important;
  // }
  .logins {
    width: 100vw;
    height: 100vh;
  }
  .txt_img_ {
    background: #b14a75;
    padding: 20px;
    margin: 20px 0;
    display: inline-block;
    border-radius: 50px;
  }
  .boxs {
    // background: url(../../assets/imgs/phonebanner.png) no-repeat;
    // background-repeat: repeat;
    // background-size: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
    // height: calc(100vh - 170px);
    // max-height: 1200px;
    height: 81%;
  }
  // .scanCode{
  //   margin-left: 0;
  // }
  .loginTitle {
    height: 8%;
    // height: 85px;
    display: flex;
    align-items: center;
    // position: fixed;
    // padding-top: 3%;
    padding-left: 5%;
    img {
      width: 40px;
      border-radius: 50%;
      margin-right: 15px;
    }
    h2 {
      color: #333;
    }
  }
  .login-form {
    width: 320px;
    height: 370px;
    background-color: #ffffff;
    padding: 20px 30px;
  }

  /* 标题 */
  .login-title {
    color: #303133;
    // text-align: end;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    width: 100%;
    // margin-right: 44px;
  }
  .log {
    // margin-left: 80px;
    width: 100%;
  }

  .scanCode {
    // width: 320px;
    // height: 370px;
    width: 344px;
    // height: 400px;
    background-color: #ffffff;
    // padding: 20px 0px;
    padding: 24px 32px;
    text-align: center;

    // margin-left: 46%;
  }
  .formTop {
    display: flex;
    margin-bottom: 25px;
    align-items: center;

    img {
      width: 60px;
      margin-right: -20px;
      margin-top: -10px;
    }
  }
  .formTop2 {
    display: flex;
    margin-bottom: 12px;
    align-items: center;

    img {
      width: 60px;
      margin-right: 6px;
      margin-top: -8px;
    }
  }
  .qrcodeImgBox {
    text-align: center;
    height: 70%;
    .loginText {
      color: #333;
      margin-top: 20px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .mark {
    // height: 204px;
    height: 280px;
  }
  .qrcodeImg {
    width: 280px;
  }

  .refreshQrcode {
    margin-top: -245px;
    height: 210px;
    width: 210px;
    margin-left: 33px;
    position: absolute;
    padding: 75px 0;
    background: rgba(0, 0, 0, 0.6);
    cursor: pointer;
  }
  .internet {
    margin-top: -300px;
    height: 210px;
    width: 210px;
    margin-left: 55px;
    position: absolute;
    padding: 75px 0;
    // background: rgba(0, 0, 0, 0.6);
    cursor: pointer;
  }
  .container {
    height: 11%;
    // height: 85px;
    // background-color: #f1f2f6;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    color: #aaa;
    font-size: 10px;
    // border-left: 2px solid #aaa;
    padding: 0 20px;
  }
  .splitLine {
    // width: 1px;
    // height: 56px;
    // background-color: #aaa;
    // margin: 0px 20px;
  }
  .box_right img {
    width: 15px;
    margin: 0 5px;
  }
  .box_right div {
    line-height: 18px;
  }
  .box_left div {
    line-height: 18px;
  }
  .record {
    display: flex;
    align-items: center;
  }
  .bottom {
    // margin-bottom: 20px;
    // margin-left: 61px;
  }
  .email {
    // margin-bottom: 14px;
  }
  .tap {
    color: rgb(103, 160, 247);
  }
  .tap:hover {
    color: rgb(0, 183, 255);
  }
  .popup {
    height: 50vw;
    padding: 10px;
    p {
      text-indent: 1rem;
      font-size: 16px;
      line-height: 1.5;
    }
  }
  .popup_top {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    img {
      width: 20px;
    }
  }
}
.bottom_txt_css {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
<style>
html {
  min-width: 100vw;
}
</style>