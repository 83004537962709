// scholarcy模块
import request from '@/utils/request_part_writful'
import qs from 'qs'


//  用户注册
export function scholarcyUser(data) {
    return request({
        url: '/api/qingli/sch/login',
        method: 'post',
        data,
        // data: qs.stringify(data),
        // headers: {
        //     "Content-Type": "application/x-www-form-urlencoded",
        // },
        // data: qs.stringify(data),
    })
}

// 获取用户信息
export function getScholarcy(query) {
    return request({
        url: '/api/qingli/sch/login',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}

// 查看任务列表
export function detail_list(query) {
    return request({
        url: '/api/qingli/sch/detail',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}




// 上传文件
export function input_file(data) {
    return request({
        url: '/api/qingli/sch/input_file',
        method: 'post',
        // data,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify(data),
    })
}
// 判断文件是否为扫描版
export function judge_file(data) {
    return request({
        url: '/api/qingli/sch/judge_file',
        method: 'post',
        // data,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify(data),
    })
}

//下载文件
export function download(query) {
    return request({
        url: '/api/qingli/sch/download',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}



//查看使用量
export function get_limit(query) {
    return request({
        url: '/api/qingli/sch/get_limit_num',
        method: 'get',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        params: query,
        // params: qs.stringify(query),
    })
}
